import React from "react";
import "../style/Footer.css";

import LogoMdf from "../assets/logo/LogoMdf.png";
import WeddingLogo from "../assets/logo/WeddingLogo.png";
import ToursLogo from "../assets/logo/ToursLogo.png";
import IncentivesLogo from "../assets/logo/IncentivesLogo.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from "react-router-dom";


function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">

                <div className="logo_container">
                    <img className="logo_principal" src={LogoMdf} alt="Logo" />

                    <div className="second_section">
                        <img className="logo_footer" src={IncentivesLogo} alt="Logo" />
                        <img className="logo_footer" src={ToursLogo} alt="Logo" />
                        <img className="logo_footer" src={WeddingLogo} alt="Logo" />
                    </div>

                </div>

                <div className="footer-column">
                    <p className="catamaranTitle-footer">MDF Incentives&Slow Tours di Maria di Fede</p>
                    <p>Tel: +39 393 7735377</p>
                    <p>Address: VIA LANDOLINA, 21 - 95045, Misterbianco (CT)</p>
                    <p>P.IVA: 054536310878</p>
                </div>


                <div className="footer-column">
                    <p className="catamaranTitle-footer">Navigate</p>
                    <ul>
                        <li><a href="#">Incentives</a></li>
                        <li><a href="#">Slow Tours</a></li>
                        <li><a href="#">Weddings</a></li>
                        <li><a href="#">Mission</a></li>
                    </ul>
                </div>


                <div className="footer-column">
                    <p className="catamaranTitle-footer">Info</p>
                    <ul>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Request</a></li>
                        <li><a href="#">Contacts</a></li>
                    </ul>
                </div>


                <div className="footer-column">
                    <p className="catamaranTitle-footer">Legal Details</p>
                    <ul>
                        <li><a href="#">Cookie Policy</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>


            <div className="footer-bottom">
                <Link src="https://semete.it/">
                    <p>Designed by Se.Me.Te SRL</p>
                </Link>
                <p>2025 Copyright MDF Incentives&Slow Tour</p>
                <div>
                    <a href='https://www.facebook.com/maria.d.fede.9' className='me-4 text-reset'>
                        <FacebookIcon />
                    </a>
                    <a href='https://www.instagram.com/maria_mdfincentives?igsh=ZnJxdzhkemRlc3N0' className='me-4 text-reset'>
                        <InstagramIcon />
                    </a>
                    <a href='https://www.linkedin.com/in/mariamdfincentives/' className='me-4 text-reset'>
                        <LinkedInIcon />
                    </a>
                </div>
            </div>
        </footer>

    )
}


export default Footer;