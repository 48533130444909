import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Travel from './pages/Travel';
import Contact from './pages/Contact';
import OurWorld from './pages/OurWorld';

import './App.css';
import Wedding from './pages/Wedding';
import Architect from './pages/Architect';
// import Carousel from './components/Carousel';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ourWorld" element={<OurWorld />} />
            <Route path="/travel" element={<Travel />} />
            <Route path="/wedding" element={<Wedding />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/architect" element={<Architect />} />
          </Routes>
        </div>
        {/* <Carousel /> */}
        <Footer />
      </div>
    </Router>

  );
}

export default App;
