import "../style/Parallax.css"
import React from "react";
import WeddingBg from "../assets/Home/WeddingBg.png";
import JourneyBg from "../assets/Home/JourneyBg.png";
import IncentivesBg from "../assets/Home/IncentivesBg.png";
import { Link } from "react-router-dom";


const Parallax = () => {


  return (
    <div className="w-[110rem]">
      <div className="px-2 md:px-2 h-[400px] bg-cover bg-center bg-no-repeat bg-fixed flex items-center justify-center"
        style={{ backgroundImage: `url(${JourneyBg})`, backgroundSize: '90%' }}>
        <h1 className="text-white text-5xl p-4 rounded-lg titleFont_journey">Journey With Us</h1>
      </div>
      <div className="flex flex-col items-center justify-center text-center px-4 py-12">
        <h1 className="pt-[50px] pb-[50px] text-xl md:text-2xl lg:text-3xl max-w-4xl catamText_fontDescrp">
          We are artisans of love stories, crafting unforgettable destination weddings in breathtaking Sicilian venues. With meticulous attention to detail, we transform your dreams into seamless, bespoke celebrations, ensuring every "I do" is a magical, stress-free moment of elegance and intimacy.
        </h1>
        <Link to="/ourWorld">
          <button className="explore_button">Explore</button>
        </Link>
      </div>

      <div className="px-2 md:px-2 h-[400px] bg-cover bg-center bg-no-repeat bg-fixed flex items-center justify-center"
        style={{ backgroundImage: `url(${IncentivesBg})`, backgroundSize: '90%' }}>
        <h1 className="text-white text-5xl p-4 rounded-lg titleFont_incentives">INCENTIVES</h1>
      </div>
      <div className="flex flex-col items-center justify-center text-center px-4 py-12">
        <h1 className="pt-[50px] pb-[50px] text-xl md:text-2xl lg:text-3xl max-w-4xl catamText_fontDescrp">
          We are artisans of love stories, crafting unforgettable destination weddings in breathtaking Sicilian venues. With meticulous attention to detail, we transform your dreams into seamless, bespoke celebrations, ensuring every "I do" is a magical, stress-free moment of elegance and intimacy.
        </h1>
        <Link to="/ourWorld">
          <button className="explore_button">Experience It</button>
        </Link>
      </div>

      <div className="px-2 md:px-2 h-[400px] bg-cover bg-center bg-no-repeat bg-fixed flex items-center justify-center"
        style={{ backgroundImage: `url(${WeddingBg})`, backgroundSize: '90%' }}>
        <h1 className="text-white text-5xl p-4 rounded-lg titleFont_wedding">Slow Weddings</h1>
      </div>
      <div className="flex flex-col items-center justify-center text-center px-4 py-12">
        <h1 className="pt-[50px] pb-[50px] text-xl md:text-2xl lg:text-3xl max-w-4xl catamText_fontDescrp">
          We are artisans of love stories, crafting unforgettable destination weddings in breathtaking Sicilian venues. With meticulous attention to detail, we transform your dreams into seamless, bespoke celebrations, ensuring every "I do" is a magical, stress-free moment of elegance and intimacy.
        </h1>
        <Link to="/ourWorld">
          <button className="explore_button">Feel the Moment</button>
        </Link>
      </div>

    </div>

  );
};

export default Parallax;

