import React from "react";


import ArchitectInc from "../assets/Architect/ArchitectInc.png";
import Mariage from "../assets/Wedding/Mariage.png";
import Flowers from "../assets/Wedding/Flowers.png";
import Guys from "../assets/Wedding/Guys.png";
import Mission from "../assets/ourWorld/Mission.png"

import { Link } from "react-router-dom";
import "../style page/Architect.css"

function Architect() {

    return (
        <div className="journey-container">
            <div className="journey-img">
                <img src={ArchitectInc} alt="Wedding page" />
            </div>


            {/* VISION */}
            <div className="vision-container">
                <h2 className="vision-title">OUR VISION</h2>
                <div className="vision-section" style={{ backgroundImage: `url(${Mission})` }}>
                    <p className="secondVision-description">“We are the Architects of Tomorrow of your incentive travels.”</p>
                </div>
                <p className="catamaranText_description">This is our powerful and visionary slogan that conveys our message of leadership, innovation, and customization. Each experience is a masterpiece of precision, designed to inspire, reward, and transform.
                    It's not just about travel—it's about immersive, meaningful moments that align with corporate values, stimulate creativity, and inspire future success.</p>
            </div>

            {/* section "back to the roots" */}
            <div className="roots_container" >
                <h1 className="philosopherText_title"> The journey: A path to inspiration </h1>
                <p className="catamaranText_description2">Every incentive travel experience is a carefully curated sequence of moments designed to inspire, engage, and leave a lasting mark. Each phase contributes to a journey that is more than just a reward—it's a transformation.</p>
                <div class="architect_container">
                    <div class="text-section">
                        <h2 class="titleArchitect_phils"> A Vision Brought to Life</h2>
                        <p class="descriptionRoots">
                            An incentive program should reflect the uniqueness of those who experience it. Every detail is meticulously shaped to reflect your company's values and aspirations, creating a journey with purpose and authenticity.
                        </p>
                    </div>
                    <div class="card-architect">
                        <img src={Mariage} alt="Journey image" />
                    </div>
                </div>

                <div class="architect_container reverse">
                    <div class="card-architect">
                        <img src={Flowers} alt="Journey image" />
                    </div>
                    <div class="text-section-inverse">
                        <h2 class="titleArchitect_phils"> Immersive Encounters & Exclusive Access</h2>
                        <p class="descriptionRoots">
                            Beyond the ordinary, each experience is designed to foster connection and engagement. From private meetings with local innovators to hands-on workshops in Sicily's rich cultural heritage, every moment is an opportunity for discovery.
                        </p>
                    </div>
                </div>

                <div class="architect_container">
                    <div class="text-section">
                        <h2 class="titleArchitect_phils"> Unforgettable Grand Finale</h2>
                        <p class="descriptionRoots">
                            Every great journey deserves a spectacular ending—one that inspires and resonates long after it's over. Imagine a private wine tasting on the slopes of Mount Etna, a gala dinner overlooking the Valley of the Temples, or sailing across the Aeolian Islands at sunset. The result? A lasting impression, woven into the memories of those who experience it.
                        </p>
                    </div>
                    <div class="card-architect">
                        <img src={Guys} alt="Journey image" />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Architect;