import React from "react";


import Welcome from "../assets/ourWorld/Welcome.png";
import Boat from "../assets/ourWorld/Boat.png";
import Mapsicily from "../assets/ourWorld/Mapsicily.png";
import Event from "../assets/ourWorld/Event.png";
import Philosophy from "../assets/ourWorld/Philosophy.png";
import OfferIncentives from "../assets/ourWorld/OfferIncentives.png";
import OfferJourney from "../assets/ourWorld/OfferJourney.png";
import OfferWedding from "../assets/ourWorld/OfferWedding.png";
import WeddingLogo from "../assets/logo/WeddingLogo.png";
import ToursLogo from "../assets/logo/ToursLogo.png";
import IncentivesLogo from "../assets/logo/IncentivesLogo.png";
import Mission from "../assets/ourWorld/Mission.png";
import Puzzle from "../assets/ourWorld/Puzzle.png";
import Poscar from "../assets/ourWorld/Poscar.png";

import "../style page/World.css"
import { Link } from "react-router-dom";




function OurWorld() {

    return (
        <div className="welcomeContainer">
            <div className="welcome-img">
                <img src={Welcome} alt="Welcome page" />
            </div>

            {/* section "Who we are" */}
            <div className="whoWeAre_section">
                <h1 className="philosopherTitle">Who we are</h1>
                <p className="catamaranText_description">MDF Incentives & Slow Tours is a boutique Leisure & MICE travel agency founded by Maria Di Fede, a certified Event Manager, a proud member of MPI International, a passionate globetrotter, and above all, a multilingual, naturally gifted Sicilian.</p>
                <p className="catamaranText_description">Guided by her expertise, creativity, and vision, the agency specializes in delivering exceptional travel experiences, events, and weddings tailored to each client's unique needs.</p>
                <div className="containerImg_boat">
                    <img src={Boat} alt="Cathedral page" />
                </div>
            </div>

            {/* section "Founder" */}
            <div className="founder_section">
                <h1 className="catamaran_title">OUR FOUNDER: <span className="philosopherTitle">Maria Di Fede</span></h1>
                <p className="catamaranText_description">Humble, flexible, empathic, wildly imaginative, and creative—these traits define not only Maria's personality but also the essence of MDF Incentives & Slow Tours.</p>
                <p className="philosopherOurWorld_descrp">“It all began with the idea of travel as a way to create experiences, share emotions, and foster connections between people. This is our idea of Luxury!”</p>
                <p className="catamaranText_description">This belief shaped the philosophy of the agency, making it more than just a travel company—it's a passion project brought to life.</p>
                <div className="containerImg_founder">
                    <img className="imgLeft" src={Mapsicily} alt="Cathedral page" />
                    <img className="imgRight" src={Event} alt="Agrigento page" />
                </div>
            </div>


            {/* Philosophy */}
            <div className="philosophy_container">
                <div className="text_container">
                    <h1 className="philosopherTitle">Our Philosophy</h1>
                    <p className="catamaranText_philosophy">At MDF Incentives & Slow Tours, travel is redefined as an immersive and authentic experience:</p>
                    <p className="philosopherPhil">Slow Tours: Back to the Roots—Live as a Local!</p>
                    <p className="catamaranText_philosophy">We design unique travel programs that embrace the heart of a destination, blending culture, tradition, and connection.</p>
                    <p className="philosopherPhil">Luxury Reimagined</p>
                    <p className="philosopherOurWorld_descrp">Luxury is not just about comfort; it's about creating unforgettable experiences that resonate on a deeper level</p>
                    <p className="catamaranText_philosophy">This vision inspires our tailor-made journeys for individuals, families, and teams, each crafted to be as unique as you are.</p>
                </div>
                <div className="containerImg_philosophy">
                    <img className="imgRight" src={Philosophy} alt="Cathedral page" />
                </div>
            </div>


            {/* OFFER */}
            <div className="section_WhatDo">
                <h2 className="catamaran_title">WHAT WE DO</h2>
                <div className="cards_container">
                    <div className="card_WhatDo">
                        <img className="imgOffer" src={OfferJourney} />
                        <Link to="/travel">
                            <img className="logoCard" src={ToursLogo} />
                        </Link>
                    </div>
                    <div className="card_WhatDo">
                        <img className="imgOffer" src={OfferWedding} />
                        <img className="logoCard" src={WeddingLogo} />
                    </div>
                    <div className="card_WhatDo">
                        <img className="imgOffer" src={OfferIncentives} />
                        <img className="logoCard" src={IncentivesLogo} />
                    </div>
                </div>
                <p className="philosopherOffer_descrp">Our creative team goes beyond expectations, providing personalized solutions that leave a lasting impression.</p>
            </div>

            {/* MISSION */}
            <div className="mission-container">
                <h2 className="mission-title">OUR MISSION</h2>
                <div className="taylored-section" style={{ backgroundImage: `url(${Mission})` }}>
                    <h1 className="mission-subtitle">People Come First.</h1>
                    <p className="mission-description">We dedicate ourselves to humanizing every step of the process, from initial design to after-sales care. Our mission is to welcome everyone not as customers but as Guests, delivering exceptional service and meaningful connections.</p>
                    <p className="secondMission-description">“We take pride in helping our partners and guests find the best solutions by doing what we love to do—and knowing how to do it!”</p>
                </div>
            </div>

            {/* CREATE */}
            <div className="section-container">
                {/* Immagine sinistra */}
                <div className="image-box box-left" style={{ backgroundImage: `url(${Poscar})`}}></div>

                {/* Testo centrale */}
                <div className="text-container">
                    <h1 className="CreateFont">Let's Create Together!</h1>
                    <p className="catamaranText_philosophy">
                        Are you ready to embark on a journey that goes beyond travel?
                        Discover the world through our unique lens.
                    </p>
                    <div href="/contact" className="contact-button">CONTACT US</div>
                </div>

                {/* Immagine destra */}
                <div className="image-box box-right" style={{ backgroundImage: `url(${Puzzle})`}}></div>
            </div>

        </div >
    )
}


export default OurWorld;