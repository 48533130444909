import React, { useState, useEffect } from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "../style page/Home.css";

import PolaroidGirl from "../assets/Home/PolaroidGirl.png";
import PolaroidSea from "../assets/Home/PolaroidSea.png";
import Ortigia from "../assets/Home/Ortigia.png";


import Slideshow from "../components/Slideshow";
import ParallaxSection from "../components/ParallaxSection.jsx";
import { Link } from "react-router-dom";



const testimonials = [
  {
    name: "Teresa May",
    position: "Founder at ET Company",
    img: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
    review:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    rating: 4.5,
  },
];

const Home = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".slide-in-left, .slide-in-right");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.2 } // Quando il 20% dell'elemento è visibile, attiva l'animazione
    );

    elements.forEach((el) => observer.observe(el));

    return () => elements.forEach((el) => observer.unobserve(el));
  }, []);

  return (
    <div className="travel-container">

      <Slideshow />
      <div className="travel-item">
        <div className="travel-description">
          <p className="philosopher_font">Unlock</p>
          <p className="petiText_font"> Exclusive </p>
          <p className="philosopher_font">Travel</p>
          <p className="philosopher_font">Experiences in</p>
          <p className="petiText_font"> Sicily </p>
        </div>
        <img src={PolaroidSea} alt="Destinazione 1" className="travel-image" />
      </div>
      <div className="travel-item reverse">
        <div className="travel-description">
          <p className="philosopher_font">You are at the</p>
          <p className="petiText_font"> Center </p>
          <p className="philosopher_font">of our</p>
          <p className="petiText_font"> Travel Universe </p>
          <Link to="/ourWorld" >
            <button className="about_button">Our World</button>
          </Link>
        </div>
        <img src={PolaroidGirl} alt="Destinazione 2" className="travel-image" />
      </div>

      {/* 
          * CATEGORIES PART 
     */}
      <div className="text-center">
        <h1 className="text-4xl catamText_fontMemories">START MAKING MEMORIES</h1>
        <ParallaxSection />
      </div>

      <div className="hero-section img-lg" style={{ backgroundImage: `url(${Ortigia})` }}>
        <h2 className="main-title">REDEFINING LUXURY IN SICILY</h2>
        <h2 className="subtitle">The luxury of time</h2>
        <p className="description">In the fast-paced modern world, Sicily offers the ultimate luxury: time. Time to slow down, immerse yourself in beauty, and savour the art of living. Here, every moment is an invitation to indulge, reflect, and connect deeply with your surroundings.
          In Sicily, luxury isn't about rushing through a packed itinerary—it's about embracing the present. It's about finding joy in the details, from the scent of fresh lemons to the warmth of Sicilian hospitality.</p>
        <button className="">
          <Link to="/travel" className="link-prop">
            DISCOVER MORE
            <ArrowRightAltIcon style={{ fontSize: '4rem', justifyContent: "center", marginRight: '8px', verticalAlign: 'middle' }} />
          </Link>
        </button>
      </div>


      {/* TESTIMONIAL  */}
      <section className="testimonial-container">
        <div className="container">
          <div className="text-center">
            <h3 className="testimonial-title">Sweet Success</h3>
            <p className="title-description">
              The best way to gain trust are client testimonials
            </p>
          </div>

          <div className="card-container">
            {/* Card left */}
            <div className="card small-card">
              <img src={testimonials[currentIndex].img} alt={testimonials[currentIndex].name} className="testimonial-img" />
              <h5 className="testimonial-name">{testimonials[currentIndex].name}</h5>
              <p className="testimonial-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam nulla, voluptatum, itaque fuga quas suscipit debitis omnis id</p>
              <div className="testimonial-rating">{'★'.repeat(Math.floor(testimonials[currentIndex].rating))} {testimonials[currentIndex].rating % 1 !== 0 ? '☆' : ''}</div>
            </div>

            {/* Card Center (in evidenza) */}
            <div className="card large-card">
              <img src={testimonials[currentIndex].img} alt={testimonials[currentIndex].name} className="testimonial-img" />
              <h5 className="testimonial-name">{testimonials[currentIndex].name}</h5>
              <p className="testimonial-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam nulla, voluptatum, itaque fuga quas suscipit debitis omnis id ullam voluptatem sint tempore, sunt asperiores alias! Repellendus facilis alias culpa facere?</p>
              <div className="testimonial-rating">{'★'.repeat(Math.floor(testimonials[currentIndex].rating))} {testimonials[currentIndex].rating % 1 !== 0 ? '☆' : ''}</div>
            </div>

            {/* Card right */}
            <div className="card small-card">
              <img src={testimonials[currentIndex].img} alt={testimonials[currentIndex].name} className="testimonial-img" />
              <h5 className="testimonial-name">{testimonials[currentIndex].name}</h5>
              <p className="testimonial-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam nulla, voluptatum, itaque fuga quas suscipit debitis omnis id</p>
              <div className="testimonial-rating">{'★'.repeat(Math.floor(testimonials[currentIndex].rating))} {testimonials[currentIndex].rating % 1 !== 0 ? '☆' : ''}</div>
            </div>
          </div>
        </div>
        <button className="button-testimonial">ALTRE RECENSIONI</button>
      </section>

    </div>
  );
};

export default Home;
