import React from "react";

import Glas from "../assets/Wedding/Glas.png";
import Ring from "../assets/Wedding/Ring.png";
import Romantic from "../assets/Wedding/Romantic.png";
import Mariage from "../assets/Wedding/Mariage.png";
import Hands from "../assets/Wedding/Hands.png";
import Flowers from "../assets/Wedding/Flowers.png";
import Guys from "../assets/Wedding/Guys.png";
import Catering from "../assets/Wedding/Catering.png";

import "../style page/Wedding.css"
import ParallaxWedding from "../components/ParallaxWedding";
import { Link } from "react-router-dom";


function Wedding() {

    return (
        <div className="journey-container">
            <div className="journey-img">
                <img src={Romantic} alt="Wedding page" />
            </div>

            <div className="OurCreation">
                {/* LEFT */}
                <div className="left-creation">
                    <img src={Hands} className="left-image" />
                </div>

                {/* RIGHT */}
                <div className="right-creation">
                    <h2 className="petiText_title">Your love, our creation</h2>
                    <p className="textCreation_catamaran">A celebration of the most important day of your life, curated with elegance, intimacy, and unforgettable beauty.</p>
                    <img src={Catering} className="imgCreation" />
                    <Link to="/contact">
                        <button className="about_button">Contact Us</button>
                    </Link>
                </div>
            </div>


            {/* section "Story Designing" */}
            <div className="slowTours_section">
                <h1 className="petiText_title">Designing your Love Story</h1>
                <h1 className="petiText_title">One Detail at a Time</h1>
                <h2 className="philosopherText_subtitle">Luxury Beyond Imagination</h2>
                <p className="catamaranText_description">Experience a new dimension of travel, where luxury meets authenticity. We specialize in creating exceptional, tailor-made itineraries for all types of travel experiences in Sicily and Italy. Every journey is curated with an unwavering commitment to excellence and meticulous attention to detail, ensuring that each moment with us is truly one-of-a-kind.</p>
                <div className="containerImg_tours">
                    <img className="imgLeft" src={Ring} alt="Ring photo" />
                    <img className="imgRight" src={Glas} alt="Glas photo" />
                </div>
            </div>

            {/* section "back to the roots" */}
            <div className="roots_container" >
                <h1 className="philosopherText_title"> Timeless Romance in the</h1>
                <h1 className="catamaranText_subtitle">Hearth of Sicily</h1>
                <p className="catamaranText_description2">Rediscover simplicity and authenticity by immersing yourself in the vibrant life of Sicily's locals. This isn't just travel—it's a journey into the heart of Sicilian culture.</p>
                <div class="weddingContainer">
                    <div class="weddingSection">
                        <h2 class="titleWeddings_phils"> Dream & Imagine</h2>
                        <h2 class="titleWeddings_phils"> WHERE IT ALL BEGINS</h2>
                        <p class="descriptionRoots">
                            Experience luxury redefined with bespoke journeys crafted for discerning travelers.
                            Step into timeless beauty, authentic traditions, and vibrant cultures set the stage
                            for unforgettable memories.<br />
                            Travel awakens emotions, a zest for living, and creates lasting memories.
                            Are you ready to take a journey like no other?
                        </p>
                    </div>
                    <div class="weddingPhoto">
                        <img src={Mariage} alt="Journey image" />
                    </div>
                </div>

                <div class="weddingContainer reverse">
                    <div class="weddingPhoto">
                        <img src={Flowers} alt="Journey image" />
                    </div>
                    <div class="weddingSection-inverse">
                        <h2 class="titleWeddings_phils"> Design & Curate</h2>
                        <h2 class="titleWeddings_phils"> CRAFTING THE MAGIC</h2>
                        <p class="descriptionRoots">
                            Experience luxury redefined with bespoke journeys crafted for discerning travelers.
                            Step into timeless beauty, authentic traditions, and vibrant cultures set the stage
                            for unforgettable memories.<br />
                            Travel awakens emotions, a zest for living, and creates lasting memories.
                            Are you ready to take a journey like no other?
                        </p>
                    </div>
                </div>

                <div class="weddingContainer">
                    <div class="weddingSection">
                        <h2 class="titleWeddings_phils"> live & Remember</h2>
                        <h2 class="titleWeddings_phils"> A DAY TO CHERISH FOREVER</h2>
                        <p class="descriptionRoots">
                            Experience luxury redefined with bespoke journeys crafted for discerning travelers.
                            Step into timeless beauty, authentic traditions, and vibrant cultures set the stage
                            for unforgettable memories.<br />
                            Travel awakens emotions, a zest for living, and creates lasting memories.
                            Are you ready to take a journey like no other?
                        </p>
                    </div>
                    <div class="weddingPhoto">
                        <img src={Guys} alt="Journey image" />
                    </div>
                </div>


                {/* <ParallaxWedding /> */}
            </div>

        </div>
    )
}

export default Wedding;