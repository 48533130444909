import React from "react";

import Getintouch from "../assets/Contact/Getintouch.png";
import PolaroidLeft from "../assets/Contact/PolaroidLeft.png";
import PolaroidRight from "../assets/Contact/PolaroidRight.png";
import Boat from "../assets/ourWorld/Boat.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import "../style page/Contact.css"
import ContactForm from "../components/Form";


function Contact() {

    return (
        <div>
            <div className="getinContainer">
                <div className="getin-img">
                    <img src={Getintouch} alt="getin page" />
                </div>
            </div>

            {/* section "are you ready" */}
            <div className="youReady_section">
                <h1 className="philosopherTitle">Are you ready to join?</h1>
                <p className="catamaranText_description">Would you like a detailed itinerary or further customization ideas for your group? Drop an email to maria@mdfincentives.com</p>
                <p className="catamaranText_description">For any inquiries, you can fill out the form below, and you will be contacted as soon as possible.</p>
            </div>
            <div className="formSection">
                <img src={PolaroidLeft} alt="Left" className="side-image left" />
                <div className="">
                    <ContactForm />
                </div>
                <img src={PolaroidRight} alt="Right" className="side-image right" />
            </div>

            {/* section "FIND US" */}
            <div className="findUs_section">
                <h1 className="catamaran_titleFont">FIND US ON SOCIAL</h1>
                <p className="findUsPhilosopher">Seguici anche sui social (da completare copywriting)</p>
                <div className="containerImg_boat">
                    <img src={Boat} alt="Cathedral page" />
                    <div className="social-icons">
                        <FacebookIcon />
                        <InstagramIcon />
                        <LinkedInIcon />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact;