import { useState } from "react";
// import { Form } from "react-router-dom";
import "../style/Form.css";

function ContactForm() {
    const [formData, setFormData] = useState({ name: "", email: "", subject: "", message: "", accepted: false });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
        setSubmitted(true);
    };

    return (
        <div className="contact-form-container">
            {submitted ? (
                <p className="text-green-500">Grazie per il tuo messaggio!</p>
            ) : (
                <form onSubmit={handleSubmit} className="formStyle">
                    <h2 className="titleInput">Name</h2>
                    <input
                        type="text"
                        name="name"
                        placeholder="Mario"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <h2 className="titleInput">Surname</h2>
                    <input
                        type="text"
                        name="cognome"
                        placeholder="Rossi"
                        value={formData.surname}
                        onChange={handleChange}
                        required
                    />
                    <h2 className="titleInput">E-MAIL*</h2>
                    <input
                        type="email"
                        name="email"
                        placeholder="es. mariorossi@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <div className="titleInput">
                        <label>OBJECT</label>
                        <input type="text"
                            name="subject"
                            placeholder="es. incentives"
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </div>
                    <h2 className="titleInput">Request</h2>
                    <textarea
                        name="message"
                        placeholder="Type..."
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <div className="checkboxForm">
                        <input type="checkbox" name="accepted" checked={formData.accepted} onChange={handleChange} />
                        <label>Accetto...</label>
                    </div>
                    <button type="submit" className="buttonSubmit">
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
}

export default ContactForm;